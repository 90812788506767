<script setup>
import { nextTick, onMounted, ref } from 'vue';
import { useForm } from 'vee-validate';
import * as yup from 'yup';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputMask from 'primevue/inputmask';
import FileUpload from 'primevue/fileupload';
import ProgressSpinner from 'primevue/progressspinner';
import BlockUI from 'primevue/blockui';
import Checkbox from 'primevue/checkbox';
import Select from 'primevue/select';
import Stepper from 'primevue/stepper';
import StepList from 'primevue/steplist';
import StepPanels from 'primevue/steppanels';
import StepPanel from 'primevue/steppanel';
import Step from 'primevue/step';
import Toast from 'primevue/toast';
import MasterClaimServicesAgreement from '../components/MasterClaimServicesAgreement.vue';
import { useToast } from 'primevue/usetoast';
import html2pdf from 'html2pdf.js';
import router from '../router/index.js';
import axios from '../Axios.js';
import { useRoute } from 'vue-router';
import { useAppStore } from '../stores/AppStore.js';

const store = useAppStore();
const route = useRoute();

const toast = useToast();
const signaturePad = ref(null);
const signature = ref('');
const files = ref([]);
const companyNameWarning = ref('');
const loading = ref(false);

const estimateFee = ref(0);
const supplementFee = ref(0);
const accountSource = ref('');

const schema = yup.object().shape({
    companyName: yup.string().required('Company Name is required'),
    companyPhone: yup.string().required('Company Phone is required'),
    companyEmail: yup.string().email('Invalid email').required('Company Email is required'),
    companyWebsite: yup.string().nullable(),
    hqAddress: yup.string().required('HQ Street Address is required'),
    hqCity: yup.string().required('HQ City is required'),
    hqState: yup.string().required('HQ State is required').min(4, 'Invalid State. Provide full state name'),
    hqZip: yup.string().matches(/^\d{5}$/, 'Invalid ZIP code').required('HQ ZIP is required'),
    primaryName: yup.string().required('Primary Contact Name is required'),
    primaryTitle: yup.string().required('Primary Contact Title is required'),
    primaryTitleOther: yup.string().when('primaryTitle', {
        is: 'Other',
        then: () => yup.string().required('Other is required.')
    }),
    primaryPhone: yup.string().required('Primary Contact Phone is required'),
    primaryEmail: yup.string().email('Invalid email').required('Primary Contact Email is required'),
    roofTypes: yup.array().of(yup.string()).required().min(1, 'At least one roof type is required'),
    avgClaims: yup.string().required('Average number of claims is required'),
    agreement: yup.boolean().oneOf([true], 'You must agree to the terms'),
    hqUnit: yup.string().nullable(),
    operationsName: yup.string().required('Operations Contact Name is required'),
    operationsPhone: yup.string().required('Operations Contact Phone is required'),
    operationsEmail: yup.string().required('Operations Contact Email is required'),
    accountingName: yup.string().required('Accounting Contact Name is required'),
    accountingPhone: yup.string().required('Accounting Contact Phone is required'),
    accountingEmail: yup.string().required('Accounting Contact Email is required'),
    billingSame: yup.boolean(),
    billingAddress: yup.string().when('billingSame', {
        is: false,
        then: () => yup.string().required('Billing Street Address is required'),
        otherwise: () => yup.string().nullable()
    }),
    billingCity: yup.string().when('billingSame', {
        is: false,
        then: () => yup.string().required('Billing City is required'),
        otherwise: () => yup.string().nullable()
    }),
    billingState: yup.string().when('billingSame', {
        is: false,
        then: () => yup.string().required('Billing State is required'),
        otherwise: () => yup.string().nullable()
    }),
    billingZip: yup.string().when('billingSame', {
        is: false,
        then: () => yup.string().matches(/^\d{5}$/, 'Invalid ZIP code').required('Billing ZIP is required'),
        otherwise: () => yup.string().nullable()
    }),
    industry: yup.string().required('Industry is required')
});

const { handleSubmit, values, defineInputBinds, errors, setFieldValue } = useForm({
    validationSchema: schema,
    initialValues: {
        companyName: '',
        companyPhone: '',
        companyEmail: '',
        companyWebsite: '',
        hqAddress: '',
        hqCity: '',
        hqState: '',
        hqZip: '',
        primaryName: '',
        primaryTitle: '',
        primaryPhone: '',
        primaryEmail: '',
        operationsName: '',
        operationsPhone: '',
        operationsEmail: '',
        accountingName: '',
        accountingPhone: '',
        accountingEmail: '',
        billingSame: true,
        roofTypes: [],
        avgClaims: '',
        agreement: false,
        primaryTitleOther: '',
        hqUnit: '',
        billingAddress: '',
        billingCity: '',
        billingState: '',
        billingZip: '',
        industry: ''
    }
});

// Create validated fields for each form input
const companyName = defineInputBinds('companyName');
const companyPhone = defineInputBinds('companyPhone');
const companyEmail = defineInputBinds('companyEmail');
const companyWebsite = defineInputBinds('companyWebsite');
const hqAddress = defineInputBinds('hqAddress');
const hqCity = defineInputBinds('hqCity');
const hqState = defineInputBinds('hqState');
const hqZip = defineInputBinds('hqZip');
const primaryName = defineInputBinds('primaryName');
const primaryTitle = defineInputBinds('primaryTitle');
const primaryPhone = defineInputBinds('primaryPhone');
const primaryEmail = defineInputBinds('primaryEmail');
const roofTypes = defineInputBinds('roofTypes');
const avgClaims = defineInputBinds('avgClaims');
const agreement = defineInputBinds('agreement');
const billingSame = defineInputBinds('billingSame');
const hqUnit = defineInputBinds('hqUnit');
const primaryTitleOther = defineInputBinds('primaryTitleOther');
const operationsName = defineInputBinds('operationsName');
const operationsPhone = defineInputBinds('operationsPhone');
const operationsEmail = defineInputBinds('operationsEmail');
const accountingName = defineInputBinds('accountingName');
const accountingPhone = defineInputBinds('accountingPhone');
const accountingEmail = defineInputBinds('accountingEmail');
const billingAddress = defineInputBinds('billingAddress');
const billingCity = defineInputBinds('billingCity');
const billingState = defineInputBinds('billingState');
const billingZip = defineInputBinds('billingZip');
const industry = defineInputBinds('industry');

onMounted(async () => {
    if (route.query.id) {
        await fetchCustomizedForm(route.query.id);
    }

    window.addEventListener('message', async (event) => {
        // Ensure message is coming from the expected origin
        //if (event.origin !== "https://your-parent-domain.com") return;

        if (event.data.id) {
            await fetchCustomizedForm(event.data.id);
        }

        // After rendering the content, send the iframe size to the parent
        await nextTick(() => {
            const height = document.documentElement.scrollHeight;
            const width = document.documentElement.scrollWidth;
            window.parent.postMessage({ height: height, width: width }, '*');
        });
    });
});

const fetchCustomizedForm = async (identifier) => {
    const resp = await axios.get(`/get-customization/${identifier}`);
    const data = await resp.data;
    console.log(data);

    supplementFee.value = data.settledSupplementFee;
    estimateFee.value = data.writtenEstimateFee;
    accountSource.value = data.accountSource;
};

const checkCompanyName = async (event) => {
    const value = event.target.value;

    if (value && value.length > 0) {
        try {
            const response = await axios.post('/check-company-name', {
                companyName: value
            });
            const data = await response.data;

            if (data.similarCompanies && data.similarCompanies.length > 0) {
                companyNameWarning.value =
                    `
                        <span class="text-red-500 font-bold">Potential duplicate found:</span>
                        <span class="text-lg border-b-2 border-red-300">${data.similarCompanies[0].name}</span>
                    `;
            } else {
                companyNameWarning.value = '';
            }
        } catch (error) {
            console.error('Error checking company name:', error);
        }
    }
};

const signatureStyles = {
    border: '#c3c3c3 2px solid',
    padding: '1rem',
    backgroundColor: '#e4e4e4'
};

const clearSig = () => {
    signaturePad.value.undoSignature();
};

const generatePdf = async () => {
    const element = document.getElementById('agreement-pdf');
    const options = {
        margin: 0.5,
        filename: 'agreement.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
    return html2pdf().set(options).from(element).output('blob');
};

const handleLogo = (event) => {
    files.value = event.files;
};

const scrolltoTop = () => {
    window.scrollTo(0, 0);
};

const checkForErrors = async () => {
    // if (companyNameWarning.value !== '') {
    //     toast.add({
    //         summary: 'Error',
    //         severity: 'error',
    //         detail: 'Potential duplicate company name found. Please verify the company name before continuing. If you find this error to be incorrect, please contact support.',
    //         life: 8000
    //     });
    //     return true;
    // }

    // if (files.value.length === 0) {
    //     toast.add({
    //         summary: 'Error',
    //         severity: 'error',
    //         detail: 'You must upload a logo before continuing',
    //         life: 5000
    //     });
    //     return true;
    // }

    // try to submit the form
    await submit();

    // check form validation errors
    if (Object.keys(errors.value).length > 0) {
        const error = Object.values(errors.value)[0];
        toast.add({
            summary: 'Error',
            severity: 'error',
            detail: error,
            life: 5000
        });
        return true;
    }

    return false;
};

const submit = handleSubmit(async (values) => {
    const { isEmpty, data } = signaturePad.value.saveSignature();
    if (isEmpty) {
        toast.add({
            summary: 'Error',
            severity: 'error',
            detail: 'You must sign before continuing',
            life: 5000
        });
        return;
    }

    loading.value = true;

    signature.value = data;

    const formDataToSubmit = new FormData();
    const pdfBlob = await generatePdf();
    formDataToSubmit.append('pdf', pdfBlob, 'agreement.pdf');

    for (const [key, value] of Object.entries(values)) {
        if (Array.isArray(value)) {
            value.forEach((item, index) => {
                formDataToSubmit.append(`${key}[${index}]`, item);
            });
        } else if (typeof value === 'boolean') {
            formDataToSubmit.append(key, value ? '1' : '0');
        } else {
            formDataToSubmit.append(key, value);
        }
    }

    formDataToSubmit.append('signature', signature.value);
    formDataToSubmit.append('accountSource', accountSource.value);
    formDataToSubmit.append('estimateFee', estimateFee.value);
    formDataToSubmit.append('supplementFee', supplementFee.value);

    if (files.value.length) {
        formDataToSubmit.append('logo', files.value[0]);
    }

    try {
        const response = await axios.post('/client-registration', formDataToSubmit, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        const data = await response.data;
        console.log(data);

        loading.value = false;

        toast.add({
            summary: 'Success',
            severity: 'success',
            detail: 'Account created successfully',
            life: 5000
        });

        await router.push({
            name: 'success',
            params: {
                accountId: data.accountId
            }
        });
    } catch (error) {
        loading.value = false;
        console.error('Error:', error);
        toast.add({
            summary: 'Error',
            severity: 'error',
            detail: 'Failed to create account',
            life: 5000
        });

        await router.push({
            name: 'error',
            params: {
                title: 'Error',
                subtitle: 'Account Creation Failed',
                message: 'An error occurred while creating your account. Please try again later.'
            }
        });
    }
});
</script>

<template>
    <div class="card flex justify-center pt-3">
        <Stepper @update:value="scrolltoTop" value="1" class="w-11/12">
            <StepList>
                <Step value="1">COMPANY DETAILS</Step>
                <Step value="2">KEY CONTACTS</Step>
                <Step value="3">AGREEMENT</Step>
            </StepList>
            <StepPanels>
                <StepPanel v-slot="{ activateCallback }" value="1">
                    <div class="flex gap-2 flex-col mt-5">
                        <!-- Company Name, Phone, Email & Logo -->
                        <div class="rounded-2xl bg-surface-100 font-medium p-8">
                            <div class="grid grid-cols-2 gap-4">
                                <div class="flex flex-col gap-2">
                                    <label for="company">Company Name</label>
                                    <InputText id="companyName"
                                               v-bind="companyName"
                                               :invalid="!!errors.companyName"
                                               aria-describedby="company-help"
                                    />
                                    <small id="company-help">Please provide your legally registered company name</small>
                                    <small v-if="errors.companyName" class="text-red-500">
                                        {{ errors.companyName }}
                                    </small>
                                    <small v-if="companyNameWarning" v-html="companyNameWarning"></small>
                                </div>
                                <div class="flex justify-start items-center">
                                    <FileUpload @select="handleLogo"
                                                chooseLabel="Upload Logo"
                                                mode="basic"
                                                accept="image/*"
                                    />
                                </div>
                                <div class="flex flex-col gap-2">
                                    <label for="phone" class="block">Company Phone</label>
                                    <InputMask id="phone"
                                               mask="(999) 999-9999"
                                               placeholder="(999) 999-9999"
                                               fluid
                                               :invalid="!!errors.companyPhone"
                                               aria-describedby="company-phone-help"
                                               @update:modelValue="setFieldValue('companyPhone', $event)"
                                               :modelValue="values.companyPhone"
                                    />
                                    <small id="company-phone-help">Your public facing company phone number</small>
                                    <small v-if="errors.companyPhone" class="text-red-500">
                                        {{ errors.companyPhone }}
                                    </small>
                                </div>
                                <div class="flex flex-col gap-2">
                                    <label for="company-email">Company Email</label>
                                    <InputText id="company-email"
                                               v-bind="companyEmail"
                                               :invalid="!!errors.companyEmail"
                                               aria-describedby="company-email-help"
                                    />
                                    <small id="company-email-help">Your public facing primary company email.</small>
                                    <small v-if="errors.companyEmail" class="text-red-500">
                                        {{ errors.companyEmail }}
                                    </small>
                                </div>
                                <div class="flex flex-col gap-2">
                                    <label for="company-website">Company Website</label>
                                    <InputText id="company-website"
                                               v-bind="companyWebsite"
                                               :invalid="!!errors.companyWebsite"
                                               aria-describedby="company-website-help"
                                    />
                                    <small id="company-website-help">Your company's public facing website</small>
                                    <small v-if="errors.companyWebsite" class="text-red-500">
                                        {{ errors.companyWebsite }}
                                    </small>
                                </div>
                                <div class="flex flex-col gap-2 w-1/3">
                                    <div>Industry</div>
                                    <Select
                                        :options="['Roofing', 'Construction', 'Insurance', 'Other']"
                                        placeholder="Select One"
                                        :invalid="!!errors.industry"
                                        :modelValue="values.industry"
                                        @change="setFieldValue('industry', $event.value)"
                                    />
                                    <small v-if="errors.industry" class="text-red-500">
                                        {{ errors.industry }}
                                    </small>
                                </div>
                            </div>
                        </div>

                        <!-- HQ Location -->
                        <div class="rounded-2xl bg-surface-100 font-medium p-8">
                            <div class="grid grid-cols-2 gap-4">
                                <div class="flex flex-col gap-2">
                                    <label for="hq-street-address">HQ Street Address</label>
                                    <InputText id="hq-street-address"
                                               v-bind="hqAddress"
                                               :invalid="!!errors.hqAddress"
                                               aria-describedby="hq-street-address-help"
                                    />
                                    <small id="hq-street-address-help">Street address of headquarters location</small>
                                    <small v-if="errors.hqAddress" class="text-red-500">
                                        {{ errors.hqAddress }}
                                    </small>
                                </div>
                                <div class="flex flex-col gap-2 w-1/2">
                                    <label for="hq-unit">Suite or Unit Number</label>
                                    <InputText id="hq-unit"
                                               v-bind="hqUnit"
                                               aria-describedby="hq-unit-help"
                                    />
                                    <small id="hq-unit-help">Suite or Unit Number of headquarters location</small>
                                </div>
                                <div class="flex gap-4 col-span-2">
                                    <div class="flex flex-col gap-2 w-1/3">
                                        <label for="hq-city">HQ City</label>
                                        <InputText id="hq-city"
                                                   v-bind="hqCity"
                                                   :invalid="!!errors.hqCity"
                                                   aria-describedby="hq-city-help"
                                        />
                                        <small id="hq-city-help">City of headquarters location</small>
                                        <small v-if="errors.hqCity" class="text-red-500">{{ errors.hqCity }}</small>
                                    </div>
                                    <div class="flex flex-col gap-2 w-2/12">
                                        <div>HQ State</div>
                                        <Select
                                            labelId="hq-state"
                                            filter
                                            :options="store.usStates"
                                            placeholder="Select One"
                                            :invalid="!!errors.hqState"
                                            :modelValue="values.hqState"
                                            @change="setFieldValue('hqState', $event.value)"
                                        />
                                        <small v-if="errors.hqState" class="text-red-500">
                                            {{ errors.hqState }}
                                        </small>
                                    </div>
                                    <div class="flex flex-col gap-2">
                                        <label for="hq-zip">HQ Zip</label>
                                        <InputMask id="hq-zip"
                                                   mask="99999"
                                                   placeholder="99999"
                                                   fluid
                                                   :invalid="!!errors.hqZip"
                                                   aria-describedby="hq-zip-help"
                                                   @update:modelValue="setFieldValue('hqZip', $event)"
                                                   :modelValue="values.hqZip"
                                        />
                                        <small id="hq-zip-help">Zip code of headquarters location</small>
                                        <small v-if="errors.hqZip" class="text-red-500">{{ errors.hqZip }}</small>
                                    </div>
                                </div>
                                <div class="flex items-center my-5 col-span-2">
                                    <Checkbox inputId="billing-same"
                                              name="billingSame"
                                              :modelValue="values.billingSame"
                                              @update:modelValue="setFieldValue('billingSame', $event)"
                                              binary
                                    />
                                    <label for="billing-same" class="ml-2">Billing address is the same as above</label>
                                </div>
                                <template v-if="!billingSame.value">
                                    <div class="flex flex-col gap-2">
                                        <label for="billing-street-address">Billing Street Address</label>
                                        <InputText id="billing-street-address"
                                                   v-bind="billingAddress"
                                                   :invalid="!!errors.billingAddress"
                                        />
                                        <small v-if="errors.billingAddress" class="text-red-500">
                                            {{ errors.billingAddress }}
                                        </small>
                                    </div>
                                    <div class="flex gap-4 col-span-2">
                                        <div class="flex flex-col gap-2 w-1/3">
                                            <label for="billing-city">Billing City</label>
                                            <InputText id="billing-city"
                                                       v-bind="billingCity"
                                                       :invalid="!!errors.billingCity"
                                            />
                                            <small v-if="errors.billingCity" class="text-red-500">
                                                {{ errors.billingCity }}
                                            </small>
                                        </div>
                                        <div class="flex flex-col gap-2">
                                            <label for="billing-state">Billing State</label>
                                            <InputText id="billing-state"
                                                       v-bind="billingState"
                                                       :invalid="!!errors.billingState"
                                            />
                                            <small v-if="errors.billingState"
                                                   class="text-red-500">
                                                {{ errors.billingState }}
                                            </small>
                                        </div>
                                        <div class="flex flex-col gap-2">
                                            <label for="billing-zip">Billing Zip</label>
                                            <InputMask id="billing-zip"
                                                       mask="99999"
                                                       placeholder="99999"
                                                       fluid
                                                       :invalid="!!errors.billingZip"
                                                       @update:modelValue="setFieldValue('billingZip', $event)"
                                                       :modelValue="values.billingZip"
                                            />
                                            <small v-if="errors.billingZip" class="text-red-500">
                                                {{ errors.billingZip }}
                                            </small>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>

                        <!-- Additional Questions -->
                        <div class="rounded-2xl bg-surface-100 font-medium p-8">
                            <div class="grid grid-cols-2 gap-4">
                                <div class="flex flex-col gap-4">
                                    <div class="text-xl">What types of roofs can we help you with ?</div>
                                    <div
                                        v-for="type in ['Single Residential Composite', 'Single Residential Specialty', 'Flat Commercial Roofs', 'Steep Slope Commercial Roofs']"
                                        :key="type"
                                        class="flex items-center"
                                    >
                                        <Checkbox :inputId="type"
                                                  name="roof-types"
                                                  :value="type"
                                                  :modelValue="values.roofTypes"
                                                  @update:modelValue="setFieldValue('roofTypes', $event)"
                                        />
                                        <label class="ml-2" :for="type">{{ type }}</label>
                                    </div>
                                    <small v-if="errors.roofTypes" class="text-red-500">{{ errors.roofTypes }}</small>
                                </div>
                                <div class="flex flex-col gap-4">
                                    <div class="text-xl">What is your average number of claims per month ?</div>
                                    <Select :options="['0-50', '51-100', '100+']"
                                            placeholder="Select One"
                                            class="w-1/2"
                                            :invalid="!!errors.avgClaims"
                                            :modelValue="values.avgClaims"
                                            @change="setFieldValue('avgClaims', $event.value)"
                                    />
                                    <small v-if="errors.avgClaims" class="text-red-500">{{ errors.avgClaims }}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex pt-6 justify-end">
                        <Button label="Next" icon="pi pi-arrow-right" @click="activateCallback('2')" />
                    </div>
                </StepPanel>
                <StepPanel v-slot="{ activateCallback }" value="2">
                    <div class="flex gap-2 flex-col mt-5">
                        <!-- Primary Contact -->
                        <div class="rounded-2xl bg-surface-100 font-medium p-8">
                            <div class="text-2xl text-orange-500 border-b-2 pb-4 mb-10">Primary
                                Contact
                            </div>
                            <div class="grid grid-cols-2 gap-4">
                                <div class="flex flex-col gap-2">
                                    <label for="primary-name">Full Name</label>
                                    <InputText id="primary-name"
                                               v-bind="primaryName"
                                               :invalid="!!errors.primaryName"
                                               aria-describedby="primary-name-help"
                                    />
                                    <small id="primary-name-help">A contact that can make business decisions</small>
                                    <small v-if="errors.primaryName" class="text-red-500">
                                        {{ errors.primaryName }}
                                    </small>
                                </div>
                                <div class="flex gap-4">
                                    <div class="flex flex-col gap-2 w-1/3">
                                        <div>Title / Position</div>
                                        <Select
                                            :options="['Owner', 'Executive', 'General Manager', 'Sales Manager', 'Sales Representative', 'Other']"
                                            placeholder="Select One"
                                            :invalid="!!errors.primaryTitle"
                                            :modelValue="values.primaryTitle"
                                            @change="setFieldValue('primaryTitle', $event.value)"
                                        />
                                        <small v-if="errors.primaryTitle" class="text-red-500">
                                            {{ errors.primaryTitle }}
                                        </small>
                                    </div>
                                    <div v-if="primaryTitle.value === 'Other'" class="flex flex-col gap-2 w-1/2">
                                        <label for="primary-title-other">Other</label>
                                        <InputText id="primary-title-other"
                                                   v-bind="primaryTitleOther"
                                                   aria-describedby="primary-title-other-help"
                                        />
                                        <small id="primary-title-other-help">What is your title or position ?</small>
                                        <small v-if="errors.primaryTitleOther" class="text-red-500">
                                            {{ errors.primaryTitleOther }}
                                        </small>
                                    </div>
                                </div>
                                <div class="flex gap-4">
                                    <div class="flex flex-col gap-2 w-1/2">
                                        <label for="primary-phone" class="block">Phone</label>
                                        <InputMask id="primary-phone"
                                                   mask="(999) 999-9999? x9999"
                                                   placeholder="(999) 999-9999"
                                                   fluid
                                                   :invalid="!!errors.primaryPhone"
                                                   aria-describedby="primary-phone-help"
                                                   @update:modelValue="setFieldValue('primaryPhone', $event)"
                                                   :modelValue="values.primaryPhone"
                                        />
                                        <small id="primary-phone-help">The primary contact's direct phone number</small>
                                        <small v-if="errors.primaryPhone" class="text-red-500">
                                            {{ errors.primaryPhone }}
                                        </small>
                                    </div>
                                    <div class="flex flex-col gap-2 w-1/2">
                                        <label for="primary-email">Email</label>
                                        <InputText id="primary-email"
                                                   v-bind="primaryEmail"
                                                   :invalid="!!errors.primaryEmail"
                                                   aria-describedby="primary-email-help"
                                        />
                                        <small id="primary-email-help">The primary contact's email address</small>
                                        <small v-if="errors.primaryEmail" class="text-red-500">
                                            {{ errors.primaryEmail }}
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Operations Contact -->
                        <div class="rounded-2xl bg-surface-100 font-medium p-8">
                            <div class="text-2xl text-orange-500 border-b-2 pb-4 mb-10">
                                Operations Contact
                            </div>
                            <div class="grid grid-cols-2 gap-4">
                                <div class="flex flex-col gap-2">
                                    <label for="operations-name">Full Name</label>
                                    <InputText id="operations-name"
                                               v-bind="operationsName"
                                               aria-describedby="operations-name-help"
                                    />
                                    <small id="operations-name-help">An authorized operations contact</small>
                                    <small v-if="errors.operationsName" class="text-red-500">
                                        {{ errors.operationsName }}
                                    </small>
                                </div>
                                <div class="flex gap-4">
                                    <div class="flex flex-col gap-2 w-1/2">
                                        <label for="operations-phone" class="block">Phone</label>
                                        <InputMask id="operations-phone"
                                                   mask="(999) 999-9999? x9999"
                                                   placeholder="(999) 999-9999"
                                                   fluid
                                                   aria-describedby="operations-phone-help"
                                                   @update:modelValue="setFieldValue('operationsPhone', $event)"
                                                   :modelValue="values.operationsPhone"
                                        />
                                        <small id="operations-phone-help">
                                            The operations contact's direct phone number
                                        </small>
                                        <small v-if="errors.operationsPhone" class="text-red-500">
                                            {{ errors.operationsPhone }}
                                        </small>
                                    </div>
                                    <div class="flex flex-col gap-2 w-1/2">
                                        <label for="operations-email">Email</label>
                                        <InputText id="operations-email"
                                                   v-bind="operationsEmail"
                                                   aria-describedby="operations-email-help"
                                        />
                                        <small id="operations-email-help">
                                            The operations contact's email address
                                        </small>
                                        <small v-if="errors.operationsEmail" class="text-red-500">
                                            {{ errors.operationsEmail }}
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Accounting Contact -->
                        <div class="rounded-2xl bg-surface-100 font-medium p-8">
                            <div class="text-2xl text-orange-500 border-b-2 pb-4 mb-10">Accounting
                                Contact
                            </div>
                            <div class="grid grid-cols-2 gap-4">
                                <div class="flex flex-col gap-2">
                                    <label for="accounting-name">Full Name</label>
                                    <InputText id="accounting-name"
                                               v-bind="accountingName"
                                               aria-describedby="accounting-name-help"
                                    />
                                    <small id="accounting-name-help">An authorized accounting contact</small>
                                    <small v-if="errors.accountingName" class="text-red-500">
                                        {{ errors.accountingName }}
                                    </small>
                                </div>
                                <div class="flex gap-4">
                                    <div class="flex flex-col gap-2 w-1/2">
                                        <label for="accounting-phone" class="block">Phone</label>
                                        <InputMask id="accounting-phone"
                                                   mask="(999) 999-9999? x9999"
                                                   placeholder="(999) 999-9999"
                                                   fluid
                                                   aria-describedby="accounting-phone-help"
                                                   @update:modelValue="setFieldValue('accountingPhone', $event)"
                                                   :modelValue="values.accountingPhone"
                                        />
                                        <small id="accounting-phone-help">
                                            The accounting contact's direct phone number
                                        </small>
                                        <small v-if="errors.accountingPhone" class="text-red-500">
                                            {{ errors.accountingPhone }}
                                        </small>
                                    </div>
                                    <div class="flex flex-col gap-2 w-1/2">
                                        <label for="accounting-email">Email</label>
                                        <InputText id="accounting-email"
                                                   v-bind="accountingEmail"
                                                   aria-describedby="accounting-email-help"
                                        />
                                        <small id="accounting-email-help">The accounting contact's email address</small>
                                        <small v-if="errors.accountingEmail" class="text-red-500">
                                            {{ errors.accountingEmail }}
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex pt-6 justify-between">
                        <Button label="Back"
                                severity="secondary"
                                icon="pi pi-arrow-left"
                                @click="activateCallback('1')"
                        />
                        <Button label="Next" icon="pi pi-arrow-right" iconPos="right" @click="activateCallback('3')" />
                    </div>
                </StepPanel>
                <StepPanel v-slot="{ activateCallback }" value="3">
                    <div class="flex gap-2 flex-col mt-5">
                        <div class="rounded-2xl bg-surface-100 font-medium p-8 flex flex-col">
                            <div class="text-2xl text-orange-500 border-b-2 pb-4 mb-10">
                                Master Claim Supplementation Services Agreement
                            </div>
                            <div>
                                Read the Master Claim Supplementation Services Agreement carefully before continuing.
                                Once read, proceed with remaining steps.
                            </div>
                            <div>
                                <MasterClaimServicesAgreement :companyName="companyName.value"
                                                              :signature="signature"
                                                              :estimateFee="estimateFee"
                                                              :supplementFee="supplementFee"
                                />
                            </div>

                            <div class="flex items-center my-5 col-span-2">
                                <Checkbox inputId="agreement"
                                          name="agreement"
                                          binary
                                          :invalid="!!errors.agreement"
                                          :value="values.agreement"
                                          :modelValue="values.agreement"
                                          @update:modelValue="setFieldValue('agreement', $event)"
                                />
                                <label for="agreement" class="ml-2">I Agree</label>
                            </div>
                            <small v-if="errors.agreement" class="text-red-500">{{ errors.agreement }}</small>
                            <div class="mt-10 flex flex-col gap-2">
                                <div class="text-lg">Signature</div>
                                <VueSignaturePad :customStyle="signatureStyles" ref="signaturePad" />
                                <Button class="w-20" link label="Clear" @click="clearSig" />
                            </div>
                        </div>
                    </div>
                    <div class="pt-6 flex justify-between">
                        <Button label="Back"
                                severity="secondary"
                                icon="pi pi-arrow-left"
                                @click="activateCallback('2')"
                        />
                        <Button :loading="loading"
                                :disabled="!values.agreement"
                                label="Submit"
                                @click="checkForErrors"
                        />
                    </div>
                </StepPanel>
            </StepPanels>
        </Stepper>
    </div>

    <Toast position="top-center" />
    <BlockUI :blocked="loading" fullScreen />
    <div v-if="loading" class="fixed top-0 left-0 w-full h-full">
        <div class="flex justify-center items-center h-full">
            <ProgressSpinner />
        </div>
    </div>
</template>
